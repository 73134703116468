import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Popover } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { SlOptions } from "react-icons/sl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import validator from "validator";
import { boundaryContext } from "../../../App";
import csvIcon from "../../../components/assets/Csv.png";
import docxIcon from "../../../components/assets/Docx.png";
import excelIcon from "../../../components/assets/Excel.jpg";
import Group from "../../../components/assets/Group.png";
import pdfIcon from "../../../components/assets/PdfIcon.svg";
import Upload_Icon from "../../../components/assets/Upload-icon.svg";
import zipIcon from "../../../components/assets/Zip.png";
import deleteIcon from "../../../components/assets/bin.svg";
import high from "../../../components/assets/high_priority.svg";
import low from "../../../components/assets/low_priority.svg";
import normal from "../../../components/assets/normal_priority.svg";
import videoIcon from "../../../components/assets/video-files-icon.png";
import wordIcon from "../../../components/assets/wordicon.svg";
import {
  deleteRequest,
  getRequest,
  patchRequest,
} from "../../../components/axiosClient";
import LoadingSpinner from "../../../components/loader/LoadingSpinner";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import {
  DELETE_TASK_BY_ID,
  DELETE_TASK_DOCUMENT,
  GET_TASK,
  GET_TASK_DOCUMENT,
  UPDATE_TASK,
  UPLOAD_TASK_DOCUMENT,
} from "../../../components/utils";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TaskActivity from "./TaskActivity";
import TaskComments from "./TaskComments";
import TaskTimeLine from "./TaskTimeLine";
import "./taskDetails.css";
import CommonDatePicker from "../../CommonDatePicker";

const TaskDetails = () => {
  const role_access =
    JSON.parse(secureLocalStorage.getItem("role_accesses")).find(
      (access) => access.module.name === "My Task"
    )?.access_type !== "Read";
  const { userData, role } = useContext(boundaryContext);
  const icons = {
    "application/msword": wordIcon,
    "application/pdf": pdfIcon,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      excelIcon,
    "application/x-zip-compressed": zipIcon,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      docxIcon,
    "text/csv": csvIcon,
    word: wordIcon,
    pdf: pdfIcon,
    xlsx: excelIcon,
    zip: zipIcon,
    docx: docxIcon,
    csv: csvIcon,
    mp4: videoIcon,
  };

  const [activeTab, setActiveTab] = useState("comments");
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const { id } = useParams(); // This will get the task id from the URL
  const hasMounted = useRef(false);
  const titleRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  const [taskDetails, setTaskDetails] = useState({
    task_id: id,
  });
  const [updatedTaskDetails, setUpdatedTaskDetails] = useState({
    task_id: id,
  });
  const [fileList, setFileList] = useState([]);
  const [taskType, setTaskType] = useState(null);

  const [dateValue, setDateValue] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [statusOptionList, setStatusOptionList] = useState([]);

  useEffect(() => {
    getTaskDetails();
    getTaskDocument();
  }, []);

  const debouncedUpdateTask = debounce((prop) => {
    updateTaskDetails(prop);
  }, 1000);

  const previousTaskDetails = useRef(taskDetails);
  const previousUpdatedTaskDetails = useRef(updatedTaskDetails);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    // Check if both taskDetails and updatedTaskDetails have changed
    const taskDetailsChanged = previousTaskDetails.current !== taskDetails;
    const updatedTaskDetailsChanged =
      previousUpdatedTaskDetails.current !== updatedTaskDetails;

    if (taskDetailsChanged && updatedTaskDetailsChanged) {
      if (!role_access) {
        return;
      }
      debouncedUpdateTask(updatedTaskDetails);
    }

    // Update refs to current values
    previousTaskDetails.current = taskDetails;
    previousUpdatedTaskDetails.current = updatedTaskDetails;

    return () => {
      debouncedUpdateTask.cancel();
    };
  }, [taskDetails, updatedTaskDetails]);

  const updateTaskDetails = async (payload) => {
    if (titleRef.current && document.activeElement === titleRef.current) {
      return;
    } else {
      previousTaskDetails.current = taskDetails;
      previousUpdatedTaskDetails.current = updatedTaskDetails;
    }

    if (payload?.title !== undefined && validator.isEmpty(payload.title)) {
      getTaskDetails();
      setUpdatedTaskDetails({
        task_id: id,
      });
      errorNotification("Task title can not be empty");
      return;
    }

    const updatedPayload = { ...payload };

    if (payload?.due_date !== undefined) {
      updatedPayload.due_date =
        typeof payload.due_date === "string" && payload.due_date.trim() === ""
          ? null
          : payload.due_date;
    }

    try {
      if (isValid) {
        const res = await patchRequest(
          `${UPDATE_TASK}`,
          updatedPayload,
          navigate
        );
        if (res?.response && res?.response.data.success) {
        } else {
          getTaskDetails();
          errorNotification(res?.errormessage);
        }
        setUpdatedTaskDetails({
          task_id: id,
        });
      }
    } catch (error) {
      getTaskDetails();
      console.error(error);
      errorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  const getTaskDetails = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`${GET_TASK}?task_id=${id}`, null, navigate);
      if (res.data && res.data.data) {
        await setDateValue(
          res.data.data?.taskData.due_date
            ? dayjs(res.data.data?.taskData.due_date).toDate()
            : null
        );
        await setTaskDetails((prevTaskDetails) => ({
          ...prevTaskDetails,
          ...res.data.data.taskData,
        }));
        // setMemberList(res.data.data?.assignedToUsers);

        let dataList;
        dataList = res.data.data?.assignedToUsers?.map((item) => {
          return {
            value: item?.id,
            label: item?.first_name + " " + item?.last_name,
            profile_image: item?.profile_image,
          };
        });

        // * To add option for none option in project and project lead task * //

        if (
          res.data.data?.taskData.task_type === "Project" ||
          res.data.data?.taskData.task_type === "Project Lead"
        ) {
          dataList.unshift({ value: null, label: "None", profile_image: null });
        }
        setMemberList(dataList);
        setStatusOptionList(res.data.data?.taskStatus);
        setTaskType(res.data.data?.taskData.task_type);
      } else {
        errorNotification(res.errormessage);
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      errorNotification(error);
    }
  };

  const getTaskDocument = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_TASK_DOCUMENT}?task_id=${id}`,
        null,
        navigate
      );
      if (res.data && res.data.data) {
        setFileList(res.data.data);
      } else {
        setFileList([]);
        errorNotification(res.errormessage);
      }
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  const handleTabChange = (e) => {
    setActiveTab(e.target.name);
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleFileUpload = async (e) => {
    const selectedFile = Array.from(e.target.files);
    handleFileOperation(selectedFile);
  };

  const handleFileOperation = async (file) => {
    if (!file || file.length > 1) {
      errorNotification("You can only upload one file at a time.");
      return;
    }

    const singleFile = file[0];
    if (!singleFile.type) {
      errorNotification(
        "Folders are not allowed. Please drag and drop files only."
      );
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("document", singleFile);
    formData.append("task_id", id);
    try {
      const config = {
        method: "post",
        url: `${UPLOAD_TASK_DOCUMENT}`,
        headers: {
          Authorization: secureLocalStorage.getItem("token"),
        },
        data: formData,
      };
      axios(config)
        .then((res) => {
          if (res?.data?.success) {
            getTaskDocument();
            hasMounted.current = false;
          }
        })
        .catch((err) => {
          errorNotification(err.response.data.message);
        });
    } catch (error) {
      errorNotification(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const droppedItems = Array.from(event.dataTransfer.files);
    handleFileOperation(droppedItems);
  };

  const removeFile = async (id) => {
    setLoading(true);
    try {
      const res = await deleteRequest(
        `${DELETE_TASK_DOCUMENT}?document_id=${id}`,
        navigate
      );
      if (res.type === 1) {
        if (res.response.data.success) {
          getTaskDocument();
          hasMounted.current = false;
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [value, setValue] = useState("");
  const [showInput, setShowInput] = useState(false);

  const handleAddTag = () => {
    setShowInput(true);
    setValue("");
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (value.length >= 2) {
      submitValue();
    } else {
      setValue("");
      setShowInput(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents default behavior (like form submission)
      if (value.length > 2) {
        submitValue();
      }
    }
  };

  const submitValue = () => {
    // Create a new array with the current tags plus the new value
    let updatedArray = [...(taskDetails.tags || []), value];

    // Update both states with the new array
    setUpdatedTaskDetails((prevState) => ({
      ...prevState,
      tags: updatedArray,
    }));

    setTaskDetails((prevState) => ({
      ...prevState,
      tags: updatedArray,
    }));
    setShowInput(false);
    // Clear the input value
    setValue("");
  };

  const handleTagRemove = (index) => {
    let array = [...taskDetails.tags];
    array.splice(index, 1);
    setTaskDetails({ ...taskDetails, tags: array });
    setUpdatedTaskDetails({ ...updatedTaskDetails, tags: array });
  };

  const DocumentTypeExtractor = (url) => {
    const getFileExtension = (url) => {
      return url.split(".").pop().split(/\#|\?/)[0];
    };
    const fileExtension = getFileExtension(url);
    return fileExtension;
  };

  const customFormatOptionLabel = ({ label, icon }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ marginRight: 10 }}>{icon}</span>
      <span>{label}</span>
    </div>
  );

  const customIcons = {
    Low: <img src={low} alt="Low" style={{ width: "14px", height: "14px" }} />,
    Medium: (
      <img
        src={normal}
        alt="Medium"
        style={{ width: "14px", height: "14px" }}
      />
    ),
    High: (
      <img src={high} alt="High" style={{ width: "14px", height: "14px" }} />
    ),
  };

  const priorityOptionsList = ["Low", "Medium", "High"];
  const priorityOption = priorityOptionsList?.map((props) => ({
    label: props,
    value: props,
    icon: customIcons[props],
  }));

  const statusOption = statusOptionList?.map((props) => ({
    label: props?.title,
    value: props?.id,
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const pop_id = open ? "simple-popover" : undefined;

  const confirmDelete = async () => {
    try {
      if (!id) {
        return;
      }
      let apiUrl = `${DELETE_TASK_BY_ID}?task_id=${id}`;
      const res = await deleteRequest(apiUrl, null, navigate);
      if (res?.type === 1) {
        navigate(-1);
        successNotification(res.response.data.message);
      } else {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  // For validation state

  const handleDateChange = (newValue, formattedDate) => {
      setDateValue(formattedDate);
      setTaskDetails((prevDetails) => ({
        ...prevDetails,
        due_date: formattedDate,
      }));
      setUpdatedTaskDetails((prevUpdatedDetails) => ({
        ...prevUpdatedDetails,
        due_date: formattedDate,
      }));
  };

  // const handleDateError = (error) => {
  //   setIsValid(!error);
  //   if (error) {
  //     // setDateValue(null);
  //   }
  //   const formattedDate =
  //     dateValue && dayjs(dateValue).isValid()
  //       ? dayjs(dateValue).format("YYYY-MM-DD")
  //       : null;

  //   setTaskDetails((prevDetails) => ({
  //     ...prevDetails,
  //     due_date: formattedDate,
  //   }));

  //   setUpdatedTaskDetails((prevUpdatedDetails) => ({
  //     ...prevUpdatedDetails,
  //     due_date: formattedDate,
  //   }));
  // };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className={`p-3 bg-white rounded-lg flex flex-col h-full`}>
      <div
        className={`go__back__btn flex flex-row gap-3 items-center justify-between mb-2`}
      >
        <div className="flex items-center gap-3">
          <span className="cursor-pointer">
            <ArrowBackIcon onClick={goBack} loading="lazy" />
          </span>
          <div>
            <button
              className={`border p-2 rounded text-sm ${taskDetails.is_completed ? "bg-[#dcf7d9]" : ""
                } hover:bg-[#dcf7d9] hover:border-green-500 cursor-pointer`}
              onClick={(e) => {
                const change = taskDetails.is_completed ? false : true;
                setTaskDetails({
                  ...taskDetails,
                  is_completed: change,
                });
                setUpdatedTaskDetails({
                  ...updatedTaskDetails,
                  is_completed: change,
                });
              }}
            >
              <span className="flex flex-row items-center gap-1">
                {<IoMdCheckmark />}
                {!taskDetails.is_completed ? "Mark complete" : "Completed"}
              </span>
            </button>
          </div>
        </div>
        <div>
          {userData?.id === taskDetails?.assignor?.id && (
            <button
              aria-describedby={pop_id}
              variant="contained"
              onClick={handleClick}
              className="cursor-pointer"
            >
              <SlOptions />
            </button>
          )}
          <Popover
            id={pop_id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="w-[170px] min-w-[170px] py-1 bg-[#FFFFFF]">
              <div
                className="flex px-[14px] py-2 hover:bg-[#F8F8F8] cursor-pointer items-center"
                onClick={(e) => {
                  confirmDelete();
                  handleClose(); // Close popover after click
                }}
              >
                <img
                  src={deleteIcon} // Replace with actual image path or import
                  alt="delete"
                  className="mr-[14px] h-4 w-4"
                />
                <p className="font-medium text-[#3C556B] text-[14px] align-middle">
                  Delete
                </p>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row grow overflow-y-auto">
        <div
          className={`lg:h-full flex flex-col ${isDragging ? "border-4 border-blue-500 bg-blue-100" : ""
            } pr-3 lg:border-r-2 w-full lg:w-[50%] overflow-y-scroll hide-scrollbar`}
          onDragOver={role_access ? handleDragOver : null}
          onDragLeave={role_access ? handleDragLeave : null}
          onDrop={role_access ? handleDrop : null}
        >
          <div
            className={`grid text-lg after:px-2.5 after:pt-2.5 after:pb-4 [&>textarea]:text-inherit after:text-inherit [&>textarea]:resize-none [&>textarea]:overflow-hidden [&>textarea]:[grid-area:1/1/2/2] after:[grid-area:1/1/2/2] after:whitespace-pre-wrap after:invisible after:content-[attr(data-cloned-val)_'_'] border-b-2 mb-2 `}
          >
            <textarea
              type="text"
              ref={titleRef}
              onInput={(e) => {
                e.target.parentNode.dataset.clonedVal = e.target.value;
              }}
              placeholder="Enter title of task"
              className={`${role_access ? "focus:border-2" : "border-0"
                } font-medium  w-full text-slate-600  appearance-none rounded px-2.5 pt-2.5 pb-4 outline-none bg-white focus:ring-2 focus:ring-indigo-100`}
              // className={`border-[#cfcbcb] w-full rounded-md px-3.5 py-2.5 font-medium text-xl h-max focus:ring-2 focus:ring-indigo-100 appearance-none `}
              value={taskDetails.title || ""}
              onChange={(e) => {
                setTaskDetails({ ...taskDetails, title: e.target.value });
                setUpdatedTaskDetails({
                  ...updatedTaskDetails,
                  title: e.target.value,
                });
              }}
              rows={"max"}
              onBlur={() => {
                updateTaskDetails(updatedTaskDetails);
              }}
              readOnly={!role_access}
            />
          </div>
          <div className="grow overflow-y-auto flex flex-col gap-1">
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-2">
              <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center w-100 md:w-50 lg:w-100 2xl:w-50">
                <span className="text-[#000000] font-medium min-w-[60px]">
                  Due Date
                </span>
                <div className="w-fit">
                <CommonDatePicker
                  value={dateValue}
                  onChange={handleDateChange}
                  label="Due Date"
                  className="common-date-picker"
                  placeholder="Enter due date"
                />
                </div>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    toolbarPlaceholder="Please enter date"
                    className="h-[40px] date-picker rounded-md w-[145px]"
                    id="date-picker"
                    value={dateValue}
                    onError={handleDateError}
                    onChange={handleDateChange}
                    inputFormat="dd-MM-yyyy"
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px 0px 7px 10px ",
                      },
                    }}
                  />
                </LocalizationProvider> */}
              </div>
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center w-100 md:w-50 lg:w-100 2xl:w-50">
                <p className="text-[#000000] font-medium min-w-[60px]">
                  Assignee
                </p>
                <Select
                  className="rounded-lg text-capitalize w-44"
                  options={memberList}
                  name="assignee to"
                  value={memberList.find(
                    (option) => option.value === taskDetails.assigned_to
                  )}
                  onChange={(selectedOption) => {
                    setTaskDetails({
                      ...taskDetails,
                      assigned_to: selectedOption ? selectedOption.value : null,
                    });
                    setUpdatedTaskDetails({
                      ...updatedTaskDetails,
                      assigned_to: selectedOption ? selectedOption.value : null,
                    });
                  }}
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 gap-2 ">
              <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center w-100 md:w-50 lg:w-100 2xl:w-50">
                <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[73px]">
                  Priority
                </span>
                <Select
                  className="basic-single text-capitalize min-w-36 border-1"
                  classNamePrefix="select"
                  formatOptionLabel={customFormatOptionLabel}
                  placeholder="Priority"
                  name="selectedClient"
                  options={priorityOption}
                  value={
                    taskDetails.priority
                      ? {
                        label: taskDetails.priority,
                        value: taskDetails.priority,
                        icon: customIcons[taskDetails.priority],
                      }
                      : null
                  }
                  onChange={(e) => {
                    setTaskDetails({
                      ...taskDetails,
                      priority: e.value,
                    });
                    setUpdatedTaskDetails({
                      ...updatedTaskDetails,
                      priority: e.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center w-100 md:w-50 lg:w-100 2xl:w-50">
                <span className="modal-heading text-[#000000] font-medium mt-[10px] min-w-[73px] ">
                  Status
                </span>
                <Select
                  className="basic-single text-capitalize  w-44 border-1"
                  classNamePrefix="select"
                  placeholder="Status"
                  name="selectedClient"
                  options={statusOption}
                  value={statusOption.find(
                    (option) => option.value === taskDetails?.task_status?.id
                  )}
                  onChange={(e) => {
                    setTaskDetails({
                      ...taskDetails,
                      task_status: {
                        id: e.value,
                      },
                    });
                    setUpdatedTaskDetails({
                      ...updatedTaskDetails,
                      status_id: e.value,
                    });
                  }}
                />
              </div>
            </div>

            {taskType === "Project" && (
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 sm:items-center">
                <p className="text-[#000000] font-medium min-w-[85px]">
                  Project
                </p>
                <input
                  placeholder="Enter OS version"
                  className="p-2 h-[40px] w-fit rounded-lg  border-1 border-[#cfcbcb] cursor-pointer"
                  type="text"
                  name="os_version"
                  value={taskDetails?.project?.name || ""}
                  readOnly
                  onClick={() => {
                    navigate(
                      `/project-list/project-details/${taskDetails?.project?.id}`
                    );
                  }}
                />
              </div>
            )}
            <div className="flex flex-col sm:flex-row gap-2 md:gap-0 items-center">
              <span className="text-[#000000] font-medium mt-[12px] min-w-[70px]">
                Tag
              </span>
              <div className="mt-2 flex flex-wrap gap-2">
                {taskDetails.tags &&
                  taskDetails.tags?.map((tags, index) => (
                    <span
                      className="flex justify-center items-center bg-green-200 text-green-700 px-2 py-1 rounded"
                      key={index}
                    >
                      {tags}
                      <span
                        className="ml-1 cursor-pointer"
                        onClick={(e) => handleTagRemove(index)}
                      >
                        <RxCross2 />
                      </span>
                    </span>
                  ))}
                {showInput ? (
                  <input
                    className="px-2 py-1 min-w-[40px] w-auto border  max-w-[100px] focus:border-[#cfcbcb] border-gray-300"
                    value={value || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                ) : (
                  <button
                    className="cursor-pointer h-8 w-8 rounded border"
                    onClick={() => handleAddTag()}
                  >
                    +
                  </button>
                )}
              </div>
            </div>

            <div className="flex-1 min-h-60">
              <h6 className="mb-2 h-auto text-[#000000] font-medium min-w-[110px]">
                Description
              </h6>
              <textarea
                type="text"
                name="description"
                className="h-[90%] w-full rounded-lg border hover:border-black p-[6px] bg-transparent"
                value={taskDetails.description || ""}
                onChange={(e) => {
                  setTaskDetails({
                    ...taskDetails,
                    description: e.target.value,
                  });
                  setUpdatedTaskDetails({
                    ...updatedTaskDetails,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-1">
              {/* Tab navigation and content */}
              <h6 className="text-[#000000] font-medium min-w-[110px]">
                Documents
              </h6>
              <div>
                <div className="flex flex-wrap gap-2 mt-2">
                  <div className="relative flex flex-row justify-center items-center h-[62px] w-[115px] rounded-xl border-2 border-dashed bg-[#F8F8F8]">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      multiple
                      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <img src={Upload_Icon} className="h-7" alt="Upload" />
                    {/* <p>Upload File</p> */}
                  </div>
                  {fileList?.map((docs, index) => {
                    const type = DocumentTypeExtractor(docs.document);

                    return (
                      <div className="relative  w-[115px] group" key={index}>
                        <div
                          className="absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer"
                          onClick={() => removeFile(docs?.id)}
                        >
                          <RxCross2 />
                        </div>
                        <div className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center">
                          <div className="flex justify-start gap-2 max-w-[240px] items-center">
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id={`tooltip-auto`}>
                                  {docs?.name}{" "}
                                </Tooltip>
                              }
                            >
                              <img
                                src={icons[type] || docs?.document}
                                alt={type}
                                className="w-[40px] h-[40px]"
                              />
                            </OverlayTrigger>
                          </div>
                          <div className="flex flex-row justify-end gap-4  items-center w-auto">
                            <div className="p-[6px] rounded-md w-fit bg-[#EFEFEF]">
                              <a
                                href={docs?.document}
                                download={docs?.name}
                                target="_blank"
                              >
                                <img
                                  src={Group}
                                  className="text-[#6F767E] cursor-pointer hover:text-blue-500"
                                  alt="group-icon"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:h-full pl-3 w-full lg:w-[50%]">
          <div className="flex flex-col justify-start gap-4">
            <ul
              className="nav nav-pills mb-3 mt-2"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link font-medium ${activeTab === "comments" ? "active" : ""
                    }`}
                  id="pills-comments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-comments"
                  type="button"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="true"
                  onClick={(e) => handleTabChange(e)}
                  name="comments"
                >
                  Comments
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link font-medium ${activeTab === "time-line" ? "active" : ""
                    }`}
                  id="pills-time-line-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-time-line"
                  type="button"
                  role="tab"
                  aria-controls="pills-time-line"
                  aria-selected="false"
                  name="time-line"
                  onClick={(e) => handleTabChange(e)}
                >
                  Time Line
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link font-medium ${activeTab === "activity" ? "active" : ""
                    }`}
                  id="pills-activity-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-activity"
                  type="button"
                  role="tab"
                  aria-controls="pills-activity"
                  aria-selected="false"
                  name="activity"
                  onClick={(e) => handleTabChange(e)}
                >
                  Activity
                </button>
              </li>
            </ul>
          </div>
          <div className="program_scroll h-[calc(100%_-_70px)]">
            <div className="tab-content h-full" id="pills-tabContent">
              <div
                className={`h-full tab-pane fade ${activeTab === "comments" ? "show active" : ""
                  }`}
                id="pills-comments"
                role="tabpanel"
                aria-labelledby="pills-comments-tab"
              >
                {activeTab === "comments" && (
                  <TaskComments
                    task_id={id}
                    call_id={2}
                    membersList={memberList}
                  />
                )}
              </div>
              <div
                className={`tab-pane fade h-full ${activeTab === "time-line" ? "show active" : ""
                  }`}
                id="pills-time-line"
                role="tabpanel"
                aria-labelledby="pills-time-line-tab"
              >
                {activeTab === "time-line" && (
                  <TaskTimeLine call_id={1} task_id={id} />
                )}
              </div>
              <div
                className={`tab-pane fade h-full ${activeTab === "activity" ? "show active" : ""
                  }`}
                id="pills-activity"
                role="tabpanel"
                aria-labelledby="pills-activity-tab"
              >
                {activeTab === "activity" && <TaskActivity task_id={id} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
