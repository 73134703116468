import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { ReactComponent as BoardIcon } from '../../../components/assets/List_view_icon.svg';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import { ReactComponent as ListIcon } from '../../../components/assets/board_view_icon.svg';
import high from '../../../components/assets/high_priority.svg';
import low from '../../../components/assets/low_priority.svg';
import normal from '../../../components/assets/normal_priority.svg';
import { getRequest, patchRequest, postRequest } from "../../../components/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import { ADD_PROJECT_LEAD, GET_EMPLOYEE_LIST_FOR_PROJECT_LEAD, GET_PROJECT_LEAD_TASK_STATUS, GET_PROJECT_LEADS, UPDATE_TASK } from "../../../components/utils";
import Modal from "../Modal/Modal";
import "./ProjectLead.css";
import ProjectLeadKanabanView from "./ProjectLeadKanabanView";
import ProjectLeadListView from "./ProjectLeadListView";
import { boundaryContext } from "../../../App";
import debounce from 'lodash.debounce';
import CommonDatePicker from "../../CommonDatePicker";

const ProjectLead = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const search_params = searchParams.get('lead');
  const [search, setSearch] = useState(search_params ? search_params : '');
  const [inputFocus, setInputFocus] = useState(false);
  const { leadBoardView, setLeadBoardView } = useContext(boundaryContext);
  const [loading, setLoading] = useState(false);
  const [taskStatusList, setTaskStatusList] = useState([]);
  const [listProjectLead, setListProjectLead] = useState([]);
  const [listShow, setListShow] = useState(false);
  const [EmployeeOptions, setEmployeeOptions] = useState([]);
  const [formFields, setFormFields] = useState({
    title: "",
    priority: null,
    assigned_to: null,
    description: "",
    due_date: null,
  });
  const navigate = useNavigate();

  const descriptionRef = useRef();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = "auto";
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
    }
  }, [formFields?.description]);


  const handleAdd = () => {
    setListShow(true);
  };

  const handleView = (id) => {
    navigate(`/task-details/${id}`);
  };

  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Project Lead")?.access_type !== "Read"

  const addProjectLead = async (payload) => {
    try {
      const res = await postRequest(ADD_PROJECT_LEAD, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getProjectLeads();
          successNotification(res.response.data.message);
          setFormFields({
            title: "",
            priority: null,
            assigned_to: null,
            description: "",
          });
          setListShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      } else {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const getProjectLeads = async () => {
    try {
      // setLoading(true);
      let apiUrl = `${GET_PROJECT_LEADS}`;
      const payload={};
      if (search && search !== '') {
        payload['search'] = search;
      }
      const res = await getRequest(apiUrl, payload, navigate);
      if (res.data && res.data.data) {
        setListProjectLead(res.data.data);
      } else {
        setListProjectLead([]);
      }
    } catch (error) {
      errorNotification(error.message || "Failed to fetch project leads");
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeList = async () => {
    try {
      const res = await getRequest(GET_EMPLOYEE_LIST_FOR_PROJECT_LEAD, null, navigate);
      if (res.data && Array.isArray(res.data.data)) {
        const options = res.data.data.map((project) => ({
          label: project.first_name + ' ' + project.last_name,
          value: project.id,
        }));
        setEmployeeOptions(options);
      } else {
        setEmployeeOptions([]);
      }
    } catch (error) {
      errorNotification(error);
    }
  };


  const getProjectLeadTaskStatus = async () => {
    try {
        // setFirstLoading(true);
        let apiUrl = `${GET_PROJECT_LEAD_TASK_STATUS}`;

        const res = await getRequest(
            apiUrl,
            null,
            navigate
        );

        if (res.data && res.data.data) {
            console.log("getProjectLeadTaskStatus", res?.data?.data);
            setTaskStatusList(res?.data?.data);
        }
    }
    catch (error) {
        errorNotification(error);
    }
}


  useEffect(() => {
    getProjectLeadTaskStatus();
    getEmployeeList();
  }, []);
  useEffect(() => {
    getProjectLeads();
  },[search]);

  const handleFormSubmit = () => {
    const { clientName, assigned_to, priority, description, due_date } = formFields;

    if (!clientName) {
      errorNotification("Please enter a Project Title");
      return;
    }
    if (!assigned_to) {
      errorNotification("Please select a Assigned");
      return;
    }


    const payload = {
      title: clientName,
      priority: priority,
      assigned_to: assigned_to.value,
      description: description,
      due_date: due_date,
      status_id: taskStatusList[0]?.id
    };

    addProjectLead(payload);
  };

  const handleLeadComplete = async (id, is_completed) => {
    if (id == null || is_completed == null || id == undefined || is_completed == undefined) {
      return;
    }
    const payload = {
      task_id: id,
      is_completed: !is_completed
    }
    try {
      const res = await patchRequest(
        `${UPDATE_TASK}`,
        payload,
        navigate
      );
      if (res?.response && res?.response.data.success) {
        // getTaskList();
      } else {
        // getProjectLeads();
        errorNotification(res?.errormessage);
      }
    } catch (error) {
      console.error(error);
      errorNotification(error);
    }
  }

  const customFormatOptionLabel = ({ label, icon }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{icon}</span>
      <span>{label}</span>
    </div>
  );

  const customIcons = {
    "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />,
    "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
    "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />
  };

  const priorityOptionsList = ["Low", "Medium", "High"];
  const priorityOption = priorityOptionsList.map((props) => ({
    label: props,
    value: props,
    icon: customIcons[props]
  }));

  const handleSearchChange = (event) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    const updatedParams = {
      ...currentParams,
      lead: event.target.value,
    };
    setSearchParams(new URLSearchParams(updatedParams));
    setSearch(event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleDueDateChange = (newDate, formattedDate) => {
    if (formattedDate || newDate === null) {
      setFormFields({
        ...formFields,
        due_date: formattedDate,
      });
    }
}

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="project__section bg-[#fff] rounded-[12px] p-3 h-full">
      <div className="project__subsection h-full flex flex-col">
        <div className="flex justify-between px-3">
          {role_accesses && (
            <div>
              <button
                className="add__project btn btn-primary mb-3"
                onClick={handleAdd}
              >
                + Add Project Leads
              </button>
            </div>
          )}
          <div className="flex justify-end items-center gap-4">
            
          <input
              type="search"
              className="project_search"
              autoFocus={inputFocus ? true : false}
              defaultValue={search}
              onChange={(e) => {
                setInputFocus(true);
                debouncedResults(e);
              }}
              placeholder="Search Lead"
            />
            <Tooltip title={!leadBoardView ? 'List view' : 'Boards view'}>
              <button onClick={() => setLeadBoardView(!leadBoardView)} className='border-1 border-[#cccccc] py-[7px] px-2 rounded-md'>
                {!leadBoardView ? <BoardIcon /> : <ListIcon />}
              </button>
            </Tooltip>
          </div>
        </div>
        {listProjectLead?.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (
          <div className="overflow-auto grow">
            {
              !leadBoardView ?
                <ProjectLeadKanabanView
                  projectLeadList={listProjectLead}
                  getLeads={getProjectLeads}
                  handleView={handleView}
                  handleLeadComplete={handleLeadComplete}
                  taskStatusList={taskStatusList}
                />
                :
                <ProjectLeadListView
                  projectLeadList={listProjectLead}
                  getLeads={getProjectLeads}
                  handleView={handleView}
                  handleLeadComplete={handleLeadComplete}
                  taskStatusList={taskStatusList}
                />
            }
          </div>
        )}
        <Modal
          id="project modal"
          key="project"
          title="Project Leads"
          className="min-w-[60vw] task-modal"
          onClose={() => {
            setListShow(false);
            setFormFields({
              clientName: "",
              priority: null,
              assigned_to: null,
              description: "",
            });
          }}
          show={listShow}
        >
          <div className='modal-body flex flex-col gap-4' >
            <Row className=''>
              <Col xs={12}>
                <h6 className="modal-heading">Project Title</h6>
                <input
                  placeholder="Enter Project Title"
                  className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] text-capitalize text-capitalize "
                  type="text"
                  name="name"
                  value={formFields.clientName}
                  onChange={(e) =>
                    setFormFields({ ...formFields, clientName: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <h6 className="modal-heading">Assigned</h6>
                <Select
                  className="basic-single text-capitalize"
                  classNamePrefix="select"
                  placeholder="Select Assigned"
                  name="selectedClient"
                  options={EmployeeOptions}
                  value={formFields.assigned_to}
                  onChange={(selectedOption) =>
                    setFormFields({ ...formFields, assigned_to: selectedOption })
                  }
                />
              </Col>
              <Col xs={4}>
                <h6 className="mb-2">Priority</h6>
                <Select
                  className="rounded-lg text-capitalize"
                  options={priorityOption}
                  formatOptionLabel={customFormatOptionLabel}
                  value={formFields.priority ?
                    { label: formFields.priority, value: formFields.priority, icon: customIcons[formFields.priority] } : null}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      priority: e.value,
                    });
                  }}
                />
                {/* <h6 className="modal-heading">Priority</h6>
                <Select
                  className="basic-single text-capitalize"
                  classNamePrefix="select"
                  placeholder="Select Priority"
                  name="selectedClient"
                  options={priorityOptions}
                  value={formFields.priority}
                  onChange={(selectedOption) =>
                    setFormFields({ ...formFields, priority: selectedOption })
                  }
                /> */}
              </Col>
              <Col xs={4}>
                <h6 className="mb-2">Due Date</h6>
                {/* <input
                  placeholder="Due Date"
                  className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                  type="date"
                  name="due_date"
                  value={formFields.due_date}
                  autoComplete="off"
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      due_date: e.target.value,
                    });
                  }}
                /> */}
                <CommonDatePicker
                  value={formFields.due_date}
                  onChange={handleDueDateChange}
                  label="Due Date"
                  className="common-date-picker"
                  placeholder="Enter due date"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h6 className="mb-2">Description</h6>
                <textarea
                  placeholder="Add description"
                  className="w-full rounded-lg border !border-[#cfcbcb] p-[6px] min-h-40"
                  type="text"
                  name="task"
                  value={formFields.description}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      description: e.target.value,
                    });
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                  onClick={handleFormSubmit}>
                  Save</button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ProjectLead;
