import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Select from "react-select";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import Upload_Icon from '../../../components/assets/Upload-icon.svg';
import { getRequest, postRequest } from '../../../components/axiosClient';
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import { ADD_BOOK, EDIT_BOOK, GET_ALL_BOOKS, GET_EMPLOYEE_DROPDOWN_LIST, ISSUED_BOOK } from '../../../components/utils';
import Modal from '../Modal/Modal';
import CommonDatePicker from '../../CommonDatePicker';

const AllBookList = () => {

    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const navigate = useNavigate();
    const [bookList, setBookList] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState();
    const [pageInformation, setPageInformation] = useState([]);
    const pageSize = 25;
    const [coverImage, setCoverImage] = useState(null);

    const listRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [bookId, setBookId] = useState(null);
    const [issueModal, setIssueModal] = useState(false);

    const [issuedBook, setIssuedBook] = useState({
        book_id: null,
        user_id: null,
        borrow_date: null,
        due_date: null
    });
    const [userList, setUserList] = useState([]);

    const [bookData, setBookData] = useState({
        title: "",
        author: "",
        published_year: "",
        publisher: "",
        ISBN: "",
        category: "",
        copies: "",
        location: ""
    });

    useEffect(() => {
        getBookList();
        getUsersList();
    }, [])

    const getBookList = async () => {
        try {
            setFirstLoading(true);
            let API_URL = `${GET_ALL_BOOKS}?page=${1}&limit=${pageSize}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setBookList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
                setPage(2);
            } else {
                setHasMoreData(false);
                errorNotification(res.errormessage);
            }
            setFirstLoading(false);
        } catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    };

    const getUsersList = async () => {
        try {
            const url = `${GET_EMPLOYEE_DROPDOWN_LIST}`;
            const res = await getRequest(url, null, navigate);
            if (res.data && Array.isArray(res.data.data)) {
                const options = res.data.data.map((project) => ({
                    label: `${project?.first_name} ${project?.last_name}`,
                    value: project?.id,
                }));
                setUserList([...options]);
            } else {
                setUserList([]);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    }

    const fetchData = useCallback(async () => {
        if (loading || (pageInformation?.current_page >= pageInformation?.last_page)) return;
        setLoading(true);
        try {
            let API_URL = `${GET_ALL_BOOKS}?page=${page}&limit=${pageSize}`;

            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setBookList((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }

            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false);
            }

            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }, [page, loading, hasMoreData]);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, clientHeight, scrollHeight } = listRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 100) {
                    fetchData();
                }
            }
        };

        const divElement = listRef.current;
        if (divElement) {
            divElement.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, [fetchData, listRef]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = [
                "image/jpeg",
                "image/png",
                "image/jpg",
                "image/svg+xml"
            ];
            if (validTypes.includes(file.type)) {
                setCoverImage(file);
            } else {
                errorNotification("Please upload a valid image file.");
                e.target.value = null;
            }
        }
    };


    const removeFile = () => {
        setCoverImage(null);
    };

    const handleAddBook = () => {
        const book = bookData;
        if (book.title === "" || book.title === null) {
            errorNotification("Title is required.");
            return;
        }

        if (book.copies === "" || book.copies === null) {
            errorNotification("Number of copies is required.");
            return;
        }

        if (book.ISBN === "" || book.ISBN === null) {
            errorNotification("ISBN number is required.");
            return;
        }

        const formData = new FormData();
        if (coverImage !== null) {
            formData.append("cover_image", coverImage);
        }
        for (const key in book) {
            if (book[key] !== "" && book[key] !== null) {
                formData.append(key, book[key]);
            }
        }
        setCoverImage(null);
        if (isEditing) {
            editBook(formData, bookId);
        } else {
            addBook(formData);
        }
    }

    const editBook = async (formData, bookId) => {
        if (bookId === null) {
            errorNotification("Book ID is required.");
            return;
        }
        formData.append("book_id", bookId);
        try {
            const config = {
                method: "patch",
                url: `${EDIT_BOOK}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        successNotification(res.data.message);
                        setIsModalOpen(false);
                        setBookData({
                            title: "",
                            author: "",
                            published_year: "",
                            publisher: "",
                            ISBN: "",
                            category: "",
                            copies: "",
                            location: ""
                        })
                        setCoverImage(null);
                        getBookList();
                    } else {
                        errorNotification(res.errormessage);
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        }
    }

    const addBook = async (formData) => {
        try {
            const config = {
                method: "post",
                url: `${ADD_BOOK}`,
                headers: {
                    Authorization: secureLocalStorage.getItem("token"),
                },
                data: formData,
            };
            axios(config)
                .then((res) => {
                    if (res?.data?.success) {
                        successNotification(res.data.message);
                        setIsModalOpen(false);
                        setBookData({
                            title: "",
                            author: "",
                            published_year: "",
                            publisher: "",
                            ISBN: "",
                            category: "",
                            copies: "",
                            location: ""
                        })
                        setCoverImage(null);
                        getBookList();
                    }
                })
                .catch((err) => {
                    errorNotification(err.response.data.message);
                });
        } catch (error) {
            errorNotification(error.message);
        }
    }

    const handleIssueBook = async () => {
        if (issuedBook?.book_id === null) {
            errorNotification("Please select a book.");
            return;
        }
        if (issuedBook?.user_id === null) {
            errorNotification("Please select a user.");
            return;
        }
        if (issuedBook?.borrow_date === null) {
            errorNotification("Please select a borrow date.");
            return;
        }
        if (issuedBook?.due_date === null) {
            errorNotification("Please select a return date.");
            return;
        }

        try {
            const res = await postRequest(ISSUED_BOOK, issuedBook, navigate);
            if (res?.response?.data) {
                successNotification(res.response.data.message);
                setIssuedBook({
                    book_id: null,
                    user_id: null,
                    borrow_date: null,
                    due_date: null
                })
                setIssueModal(false);
                getBookList();
            } else {
                errorNotification(res.errormessage);
            }

        } catch (e) {
            errorNotification(e.message);
            setIssuedBook({
                book_id: null,
                user_id: null,
                borrow_date: null,
                due_date: null
            })
        }
    }

    return firstLoading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-full flex flex-col py-2'>
            <div className='mb-2'>
                <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                    onClick={() => {
                        setIsEditing(false);
                        setIsModalOpen(true);
                    }}
                >
                    + Add Book
                </button>
            </div>
            <div className='grow overflow-y-auto'>
                {bookList?.length === 0 ? (
                    <div className="daily_updates_section bg-white ">
                        <div className="no__transcation">
                            <img src={noDataFound} alt={noDataFound} loading="lazy" />
                        </div>
                    </div>
                ) : (
                    <div className='h-full flex flex-col justify-between overflow-x-auto overflow-y-auto' ref={listRef}>
                        <table className="table max-w-full">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium ">
                                        Title
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Author
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Publisher
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Available Copies
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Category
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookList?.map((item, index) => {
                                        return (
                                            <tr className={`align-middle border-b-0 overflow-hidden`}
                                                key={index}
                                            >
                                                <td className="border-b-0 !border-b-[#EFEFEF] p-2 !max-w-[30vw] overflow-hidden">
                                                    <div className='flex items-center w-full gap-1'>
                                                        <div className="truncate">
                                                            {item?.title || "--"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] truncate">
                                                    {item?.author || "--"}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] truncate">
                                                    {item?.publisher || "--"}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.available_copies || "0"}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    {item?.category || "--"}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF]">
                                                    <div className='flex gap-2 items-center justify-end'>
                                                        {
                                                            item?.available_copies > 0 &&
                                                            <button className='border-0 text-white rounded-md py-2 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                                                                onClick={() => {
                                                                    setIssuedBook({
                                                                        ...issuedBook,
                                                                        book_id: item?.id
                                                                    })
                                                                    setIssueModal(true)
                                                                }}
                                                            >
                                                                Issue
                                                            </button>
                                                        }
                                                        <button className='border-0 text-white rounded-md py-2 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                                                            onClick={() => {
                                                                setIsEditing(true);
                                                                setBookData(item);
                                                                setCoverImage(item.cover_image);
                                                                setIsModalOpen(true);
                                                                setBookId(item.id);
                                                            }}
                                                        >
                                                            Edit
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {
                            loading &&
                            <div className='w-full my-2 flex items-center justify-center'>
                                Loading...
                            </div>
                        }
                    </div>
                )}

                <Modal
                    className=""
                    key="project"
                    title={isEditing ? "Edit book details" : "Add new Book"}
                    onClose={() => { setIsModalOpen(false); }}
                    show={isModalOpen}
                >
                    <div className='p-2 flex flex-col gap-4'>
                        <Row>
                            <Col xs={12}>
                                <h6 className="mb-2">Title</h6>
                                <input
                                    placeholder="Add title"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                    type="text"
                                    name="title"
                                    autoComplete="off"
                                    value={bookData.title || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            title: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <h6 className="mb-2">Author</h6>
                                <input
                                    placeholder="Add author name"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] text-capitalize"
                                    type="text"
                                    name="author"
                                    autoComplete="off"
                                    value={bookData.author || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            author: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={6}>
                                <h6 className="mb-2">Publisher</h6>
                                <input
                                    placeholder="Add publisher name"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]"
                                    type="text"
                                    name="publisher"
                                    autoComplete="off"
                                    value={bookData.publisher || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            publisher: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <h6 className="mb-2">ISBN Number</h6>
                                <input
                                    placeholder="Add ISBN Number"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] text-capitalize"
                                    type="text"
                                    name="ISBN number"
                                    autoComplete="off"
                                    value={bookData.ISBN || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            ISBN: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={3}>
                                <h6 className="mb-2">Published Year</h6>
                                <input
                                    placeholder="Add publisher year"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]"
                                    type="number"
                                    name="published year"
                                    autoComplete="off"
                                    value={bookData.published_year || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            published_year: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={5}>
                                <h6 className="mb-2">Category</h6>
                                <input
                                    placeholder="Add category"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]"
                                    type="text"
                                    name="category"
                                    autoComplete="off"
                                    value={bookData.category || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            category: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <h6 className="mb-2">Available Copies</h6>
                                <input
                                    placeholder="Add copies"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] text-capitalize"
                                    type="number"
                                    name="ISBN number"
                                    autoComplete="off"
                                    value={bookData.copies || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            copies: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                            <Col xs={4}>
                                <h6 className="mb-2">Location</h6>
                                <input
                                    placeholder="Add location"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px]"
                                    type="text"
                                    name="location"
                                    autoComplete="off"
                                    value={bookData.location || ""}
                                    onChange={(e) => {
                                        setBookData({
                                            ...bookData,
                                            location: e.target.value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <div className="flex flex-col">
                                    <label className="modal-heading mb-2">Cover Image</label>
                                    <div className="flex items-center">
                                        {coverImage ? (
                                            <div className='relative  w-[290px] group'>
                                                <div className='absolute rounded-full p-0.5 bg-white top-[-6px] right-[-6px] border-1 hidden group-hover:block cursor-pointer'
                                                    onClick={removeFile}
                                                >
                                                    <RxCross2 />
                                                </div>
                                                <div
                                                    className="flex flex-row justify-between items-center border w-full p-2.5 border-[#cfcbcb] rounded-lg text-center"
                                                >
                                                    <div className="flex justify-start gap-2 max-w-[240px] items-center">
                                                        <div className="w-[40px] h-[40px]">
                                                            <img
                                                                src={
                                                                    (coverImage?.name) ? URL.createObjectURL(coverImage) : coverImage
                                                                }
                                                                alt={coverImage?.type}
                                                                className="w-[40px] h-[40px]"
                                                            />
                                                        </div>
                                                        <div className="w-[190px]">
                                                            <p className="text-sm text-start leading-4 font-medium text-[#000000] overflow-hidden whitespace-nowrap overflow-ellipsis w-full">
                                                                {coverImage.name || "Cover Image"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={`relative flex flex-row justify-center items-center h-[62px] w-[290px] rounded-xl border-2 border-dashed bg-[#F8F8F8] `}>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    accept=".jpg,.jpeg,.png,.svg"
                                                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                                    onChange={(e) => handleFileChange(e)}
                                                />
                                                <img src={Upload_Icon} className="h-3.5 mr-2" alt="Upload" />
                                                <p>Upload File</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={8} className='flex justify-end items-end'>
                                <div className='text-right'>
                                    <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                                        onClick={() => handleAddBook()}
                                    >
                                        {isEditing ? "Edit book" : "Add Book"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    className="issueListModal"
                    title="Issue Book"
                    onClose={() => { setIssueModal(false); }}
                    show={issueModal}
                >

                    <div className='flex flex-col gap-4 p-2'>
                        <Row>
                            <Col xs={12}>
                                <h6 className='mb-2'>Issued to</h6>
                                <Select
                                    placeholder="Select user"
                                    className="rounded-lg text-capitalize"
                                    type="text"
                                    options={userList}
                                    name="assignee to"
                                    value={userList.find(option => option.value === issuedBook.user_id)}
                                    onChange={(selectedOption) =>
                                        setIssuedBook({ ...issuedBook, user_id: selectedOption ? selectedOption.value : null })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <h6 className='mb-2'>Borrowed Date</h6>
                                {/* <input
                                    placeholder="Add Borrowed date"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                    type="date"
                                    name="borrowed_date"
                                    value={issuedBook.borrow_date || ""}
                                    onChange={(e) => {
                                        setIssuedBook({
                                            ...issuedBook,
                                            borrow_date: e.target.value,
                                        });
                                    }}
                                /> */}
                                <CommonDatePicker
                                    value={issuedBook.borrow_date}
                                    onChange={(newDate, formattedDate) => setIssuedBook({
                                        ...issuedBook,
                                        borrow_date: formattedDate,
                                    })}
                                    label="Borrowed Date"
                                    className="common-date-picker"
                                    placeholder="Enter Borrowed Date"
                                />
                            </Col>
                            <Col xs={6}>
                                <h6 className='mb-2'>Return date</h6>
                                {/* <input
                                    placeholder="Add Due date"
                                    className="h-10 w-full rounded-lg border !border-[#cfcbcb] p-[6px] "
                                    type="date"
                                    name="due_date"
                                    min={issuedBook.borrow_date}
                                    value={issuedBook.due_date || ""}
                                    onChange={(e) => {
                                        setIssuedBook({
                                            ...issuedBook,
                                            due_date: e.target.value,
                                        });
                                    }}
                                /> */}
                                <CommonDatePicker
                                    value={issuedBook.due_date}
                                    onChange={(newDate, formattedDate) => setIssuedBook({
                                        ...issuedBook,
                                        due_date: formattedDate,
                                    })}
                                    label="Due Date"
                                    className="common-date-picker"
                                    placeholder="Enter Due Date"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="text-right">
                                <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53] cursor-pointer'
                                    onClick={() => handleIssueBook()}
                                >
                                    Issue Book
                                </button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
            <style>
                {
                    `
                        .issueListModal .modal-content{
                            width: 600px; 
                            overflow-y: unset !important;
                        }
                    `
                }
            </style>
        </div>
    )
}

export default AllBookList