import React, { useState } from 'react';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const CommonDatePicker = ({
  value,
  onChange,
  label = 'Select Date',
  inputFormat = 'dd-MM-yyyy',
  placeholder = 'Please enter date',
  className = 'common-date-picker w-full',
  id = 'date-picker',
  onError,
  max=null,
  min=null,
  readOnly = false,
}) => {
    const [localValue, setLocalValue] = useState(value ? new Date(value) : null);
    const [isValid, setIsValid] = useState(true);
    console.log("value=====>", value, localValue ,label); // Log the value

  // Handle date change and calculate formatted date
  const handleDateChange = (newValue) => {
    setLocalValue(newValue);
    const formattedDate = newValue && dayjs(newValue).isValid()
      ? dayjs(newValue).format('YYYY-MM-DD')
      : null;

    if (onChange && (formattedDate || newValue === null)) {
      onChange(newValue, formattedDate); // Pass both raw and formatted date
    }
  };

  // Handle date error
  const handleDateError = (error) => {
    setIsValid(!error);
    if (onError) {
      onError(error);
    }
  };

  return (
    <div className={`common-date-picker w-full ${className}`}>
      <LocalizationProvider className="w-full" dateAdapter={AdapterDateFns} >
        <DesktopDatePicker
          toolbarPlaceholder={placeholder}
          className="h-[40px] date-picker rounded-md w-full"
          id={id}
          sx={{
            '& .MuiInputBase-root': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            },
          }}
          readOnly={readOnly}
          maxDate={max}
          minDate={min}
          value={localValue}
          onError={handleDateError}
          onChange={handleDateChange}
          inputFormat={inputFormat}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!isValid}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CommonDatePicker;



// import { TextField } from '@mui/material';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import dayjs from 'dayjs';
// import React, { useEffect, useState } from 'react'

// const CommonDatePicker = ({ value, onChange }) => {

//     const [dateValue, setDateValue] = useState();
//     const [isValid, setIsValid] = useState(true);

//     useEffect(() => {
//         setDateValue(value);
//     }, []);

// console.log("dateValue", dateValue, value);


//     const handleDateChange = (newValue) => {
//         setDateValue(newValue);
        
//         if (isValid === true && newValue && dayjs(newValue).isValid()) {
//             const formattedDate =  dayjs(newValue).format('YYYY-MM-DD');
//             onChange(formattedDate);
//         }else{
//             onChange(null);
//         }
//     };
//     const handleDateError = (error) => {
//         setIsValid(!error);
//         if (error) {
//             return;
//         }
//         if(dateValue && dayjs(dateValue).isValid()){
//             const formattedDate = dayjs(dateValue).format('YYYY-MM-DD');
//             onChange(formattedDate);
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <DesktopDatePicker
//                 slotProps={{
//                     actionBar: {
//                         actions: ['clear'],
//                     },
//                 }}
//                 toolbarPlaceholder="Please enter date"
//                 className="h-[40px] date-picker rounded-lg w-full"
//                 id="date-picker"
//                 value={dateValue}
//                 onError={handleDateError}
//                 onChange={handleDateChange}
//                 format="dd/MM/yyyy"
//                 renderInput={(params) => <TextField {...params} />}
//             />
//         </LocalizationProvider>
//     )
// }

// export default CommonDatePicker