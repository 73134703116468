import { Avatar } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { GET_MY_TASK_TIMER, GET_TASK_TIMER, SEND_TIMER_REGULATION_REQUEST } from '../../../components/utils';
import { getRequest, postRequest } from '../../../components/axiosClient';
import { useNavigate } from 'react-router-dom';
import { errorNotification, successNotification } from '../../../components/toast-notification/common-toast';
import Modal from '../Modal/Modal';
import { Row, Col } from "react-bootstrap";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { boundaryContext } from '../../../App';
import CommonDatePicker from '../../CommonDatePicker';

dayjs.extend(customParseFormat);


const TaskTimeLine = ({ task_id }) => {

  const [loading, setLoading] = useState(false);
  const { userData } = useContext(boundaryContext);
  const [timeLineList, setTimeLineList] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    task_timer_id: null,
    startTime: null,
    endTime: null,
    taskDate: '',
    note: ''
  })

  useEffect(() => {
    getTimeLineData();
  }, []);

  const handleModalData = (data) => {
    setModalData({
      task_timer_id: data.id,
      startTime: dayjs(data.start_time, 'HH:mm:ss').format('HH:mm'),
      endTime: dayjs(data.end_time, 'HH:mm:ss').format('HH:mm'),
      taskDate: data.date,
    })
  }

  const getTimeLineData = async () => {
    try {
      setLoading(true);
      let apiUrl;
      apiUrl = `${GET_TASK_TIMER}?task_id=${task_id}`;
      const res = await getRequest(
        apiUrl,
        null,
        navigate
      );
      if (res.data && res.data.data) {
        setTimeLineList(res.data.data);
      } else {
        errorNotification(res.errormessage);
        setTimeLineList([]);
      }
      setLoading(false);
    }
    catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  }

  const handleAddRequest = () => {
    if (modalData.startTime === null) {
      errorNotification("Please select start time");
      return;
    } else if (modalData.endTime === null) {
      errorNotification("Please select end time");
      return;
    } else if (modalData.endTime < modalData.startTime) {
      errorNotification("Start time must be greater than end time");
      return;
    } else if (modalData.taskDate === null) {
      errorNotification("Please select task date");
      return;
    } else if (modalData.note === '') {
      errorNotification("Note is required");
      return;
    }
    const updatedRequestData = {
      task_timer_id: modalData.task_timer_id,
      note: modalData.note,
      start_time: dayjs(modalData.startTime, 'HH:mm').set('second', 59).format('HH:mm:ss'),
      end_time: dayjs(modalData.endTime, 'HH:mm').format('HH:mm:ss'),
      date: modalData.taskDate
    };

    sendRequest(updatedRequestData);
  }

  const sendRequest = async (payload) => {
    try {
      setLoading(true);
      const res = await postRequest(
        `${SEND_TIMER_REGULATION_REQUEST}`,
        payload,
        navigate
      );
      if (res.type === 1) {
        successNotification(res.response.data.message);
        if (res.response.status === 200) {
          getTimeLineData();
        }
        setShowModal(false);
        setModalData({
          task_timer_id: null,
          startTime: null,
          endTime: null,
          taskDate: '',
          note: ''
        })
      } else {
        errorNotification(res.errormessage);
      }
      setLoading(false);
    }
    catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  }
  const handleDateChange = (newDate, formattedDate) => {
    setModalData({ ...modalData, taskDate: formattedDate });
  }

  return (
    <div className='py-2 lg:h-[calc(100dvh_-_300px)] overflow-y-scroll hide-scrollbar'>
      {
        timeLineList.length <= 0 ?
          <div className='text-center'>There is no Time Line yet.</div>
          :
          <table className='table max-w-full text-sm'>
            <thead>
              <tr className='align-middle '>
                <th className='font-medium !border-b-[#EFEFEF]'>Who</th>
                <th className='font-medium !border-b-[#EFEFEF]'>Date</th>
                <th className='font-medium !border-b-[#EFEFEF]'>Start</th>
                <th className='font-medium !border-b-[#EFEFEF]'>End</th>
                <th className='font-medium !border-b-[#EFEFEF]'>Hours</th>
                <th className='font-medium !border-b-[#EFEFEF]'></th>
              </tr>
            </thead>
            <tbody>
              {
                timeLineList && timeLineList.map((item, index) => {

                  const timeDuration = dayjs.duration(item.duration, 'seconds');
                  const hours = String(timeDuration.hours()).padStart(2, '0');
                  const minutes = String(timeDuration.minutes()).padStart(2, '0');
                  const formattedTime = `${hours}:${minutes}`;

                  return (
                    <tr className='align-middle' key={index}>
                      <td className='!border-b-[#EFEFEF]'>
                        <span className='flex justify-start items-center gap-2'>
                          <Avatar
                            className='!h-8 !w-8 rounded-full border'
                            src={item.user.profile_image}
                          />
                          {item?.user.first_name} {item?.user.last_name}
                        </span>
                      </td>
                      <td className='!border-b-[#EFEFEF]'>{dayjs(item?.date).format("DD-MM-YYYY")}</td>
                      <td className='!border-b-[#EFEFEF]'>
                        {dayjs(item?.start_time, 'HH:mm:ss').format('hh:mm A')}
                      </td>
                      <td className='!border-b-[#EFEFEF]'>
                        {item?.end_time ? dayjs(item?.end_time, 'HH:mm:ss').format('hh:mm A') : '--'}
                      </td>
                      <td className='!border-b-[#EFEFEF]'>{formattedTime}</td>
                      <td className='text-right'>
                        {
                          userData.id === item?.user_id &&
                          <button type='button' className='border-0 text-white rounded-md py-2 px-1.5 text-sm font-medium bg-[#f47e53]'
                            onClick={() => {
                              setShowModal(true)
                              handleModalData(item);
                            }}
                          >
                            Request
                          </button>
                        }
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
      }


      <Modal
        className="regularization-request"
        title="Request timer change"
        onClose={() => {
          setShowModal(false);
          setModalData({
            task_timer_id: null,
            startTime: null,
            endTime: null,
            taskDate: '',
            note: ''
          })
        }}
        show={showModal}
      >
        <div className='modal-body flex flex-col gap-4' >
          <Row className=''>
            <Col xs={4}>
              <h6 className="mb-2">Start Time</h6>
              <input
                placeholder="Task"
                className="h-10 w-full rounded-lg border p-[6px] text-capitalize"
                type="time"
                name="task"
                value={modalData.startTime}
                autoComplete="off"
                max={modalData.endTime || "23:59"}  // Set the max value to endTime or default to the end of the day
                onChange={(e) => {
                  const startTime = e.target.value;
                  setModalData((prevData) => ({
                    ...prevData,
                    startTime,
                    // endTime: prevData.endTime && prevData.endTime < startTime ? "" : prevData.endTime
                  }));
                }}
              />
            </Col>
            <Col xs={4}>
              <h6 className="mb-2">End Time</h6>
              <input
                placeholder="Task"
                className="h-10 w-full rounded-lg border p-[6px] text-capitalize"
                type="time"
                name="task"
                value={modalData.endTime}
                autoComplete="off"
                min={modalData.startTime || "00:00"}  // Set the min value to startTime or default to the start of the day
                onChange={(e) => {
                  const endTime = e.target.value;
                  setModalData((prevData) => ({
                    ...prevData,
                    endTime,
                    // startTime: prevData.startTime && prevData.startTime > endTime ? "" : prevData.startTime // Reset startTime if it becomes invalid
                  }));
                }}
              />
            </Col>

            <Col xs={4}>
              <h6 className="mb-2">Task Date</h6>
              {/* <input
                placeholder="Task"
                className="h-10 w-full rounded-lg border p-[6px]"
                type="date"
                name="task"
                value={modalData.taskDate}
                autoComplete="off"
                onChange={(e) => {
                  setModalData({ ...modalData, taskDate: e.target.value });
                }}
              /> */}
              <CommonDatePicker
                value={modalData.taskDate}
                onChange={handleDateChange}
                label="Date"
                className="common-date-picker"
                placeholder="Enter Date"
              />
            </Col>
          </Row>
          <Row className=''>
            <Col xs={12}>
              <h6 className="mb-2">Note</h6>
              <textarea
                placeholder="Enter Note"
                className="h-10 w-full rounded-lg border p-[6px] min-h-28"
                type="text"
                name="task"
                value={modalData.note}
                autoComplete="off"
                onChange={(e) => {
                  setModalData({ ...modalData, note: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row className=''>
            <Col xs={12}>
              <div className='flex flex-row justify-end items-end pl-4 h-full'>
                <button className='border-0 text-white rounded-md py-2.5 px-3.5 font-medium bg-[#f47e53]'
                  onClick={() => {
                    handleAddRequest();
                  }}
                >Send</button>

              </div>
            </Col>
          </Row>
        </div>

      </Modal>
      <style>
        {`
          .hide-scrollbar {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
          }

          .regularization-request .modal-content {
              width : 1000px;
          }
        `}
      </style>
    </div>
  )
}

export default TaskTimeLine